<template>
  <!-- eslint-disable -->
  <portal to="modal">
    <el-modal
      id="modal"
      size="xs"
      :on-dismiss="() => $emit('is-open', false)"
    >
      <div class="container el-login-modal mt-12">
        <div id="el-modal-dialog">
          <div class="modal_header">
            <h3>{{ isLogin ? 'Login' : 'Sign Up' }}</h3>
            <button type="button" class="mfp-close" @click.prevent="$emit('is-open', false)"></button>
          </div>
          <div class="cp-container">
            <div class="form-part">
              <div v-if="isLogin">
                <div class="cp-heading">
                  <h5>Welcome Back, Please Login to Your account.</h5>
                </div>
                <div class="social-login">
                  <vue-telegram-login
                    mode="callback"
                    telegram-login="ElrosBot"
                    @callback="onSuccess"
                  />
                  <div class="clearfix"></div>
                  <span>or</span>
                </div>
                <div class="cp-body">
                  <form @submit.prevent="onLogin">
                    <div class="form-group">
                      <input class="form-control" v-model="user.email" type="text" placeholder="Email or Username" required="">
                    </div>
                    <div class="form-group">
                      <input class="form-control" v-model="user.password" type="password" placeholder="password" required="">
                    </div>
                    <div class="row">
                      <div class="col">                        
                        <p class="text-right"><a class="forgot-link" href="" @click.prevent="$router.push('forgot-password');$emit('is-open', false)">Forgot password?</a></p>
                      </div>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="btn el-btn">Login</button>
                      <a href="" @click.prevent="isLogin = false" class="btn el-btn">Sign Up</a>
                    </div>
                  </form>
                </div>
              </div>
              <div v-else>
                <div class="cp-heading">
                  <h5>Sign up using</h5>
                </div>
                <div class="social-login">
                  <vue-telegram-login
                    mode="callback"
                    telegram-login="ElrosBot"
                    @callback="onSuccess"
                  />
                  <div class="clearfix"></div>
                  <span>or</span>
                </div>
                <div class="cp-body">
                  <form @submit.prevent="onRegister">
                    <div class="form-group">
                      <input class="form-control" v-model="user.username" type="text" placeholder="Username" required="">
                    </div>
                    <div class="form-group">
                      <input class="form-control" v-model="user.email" type="email" placeholder="Email Address" required="">
                    </div>
                    <div class="form-row">
                      <div class="col form-group">
                        <input class="form-control" v-model="user.password" type="password" placeholder="Password" required="" >
                      </div>
                      <div class="col form-group">
                        <input class="form-control" v-model="user.confirmPassword" type="password" placeholder="Confirm Password" required="">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col form-group">
                        <input class="form-control" v-model="user.referral_id" type="text" placeholder="Referral Id">
                      </div>
                    </div>
                    <div class="form-group">
                      <p class="text-left remember-me-checkbox"><label><input type="checkbox" name="remember" v-model="agreedTerms">I agree with the website's <a href="#">Terms and conditions</a></label></p>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <button type="submit" class="btn el-btn">Register</button>
                      </div>
                      <div class="col">
                        <p class="text-right">Already a member? <a href="" @click.prevent="isLogin = true">Sign in</a></p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-modal>
  </portal>
</template>

<script>
  /* eslint-disable */
  import Vue from 'vue';
  import logo from '@/assets/png/black-gold.png';
  import { vueTelegramLogin } from 'vue-telegram-login';
  import { mapGetters, mapActions } from 'vuex';

  export default Vue.extend({
    name: 'el-login-modal',

    components: {
      'vue-telegram-login': vueTelegramLogin,
    },

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean,
      },
    },

    data: () => ({
      logo,
      isLogin: true,
      agreedTerms: true,
      user: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        referral_id: '',
        type: 'users',
        id: 'test-id',
      },
    }),

    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
        isFetchingUser: 'session/isFetchingUser',
      }),
    },

    created() {
      const referralId = localStorage.getItem('referralId');
      if (referralId) {
        this.isLogin = false;
        this.user.referral_id = referralId;
      }
    },

    methods: {
      ...mapActions({
        login: 'session/login',
        signUp: 'session/signUp',
        signIn: 'session/signIn',
        tgSignIn: 'session/tgSignIn',
      }),
      onSuccess(user) {
        this.tgSignIn({ credentials: { ...user, type: 'users' }});
      },
      onRegister() {
        if (!this.agreedTerms) {
          this.$toasts.toast({
            id: 'warning-toast',
            intent: 'warning',
            message: 'In order to create your account, you must agree to our Terms and Conditions.',
            title: 'Attention!',
          });

          return;
        }

        if (this.user.password !== this.user.confirmPassword) {
          this.$toasts.toast({
            id: 'warning-toast',
            intent: 'warning',
            message: 'The confirmation password does not match the original password. Please make sure the passwords match before continuing.',
            title: 'Attention!',
          });

          return;
        }
        this.signUp({ credentials: { ...this.user }});
      },
      onLogin() {
        if (!this.user.email && !this.user.password) {
          this.$toasts.toast({
            id: 'warning-toast',
            intent: 'warning',
            message: 'Please enter your email and password correctly. Make sure you are using the correct information before trying to log in again.',
            title: 'Attention!',
          });

          return;
        }

        this.signIn({ credentials: { ...this.user } });
      },
    },
  });
</script>
