<template>
  <!--footer Start-->
  <footer class="footer-3">
    <div class="container">
      <div class="row">
        <div class="col-md-6 footer-box-1">
          <div class="footer-logo">
            <a href="#" title="" @click="scrollToTop()"><img width="64" :src="logo" alt="Cp Silver"></a>
          </div>
          <p>Elros stands as a robust platform that enables investors to generate revenue from multiple sources and use the revenue to accumulate low-risk large-cap cryptos. Our platform’s special power is the seamless facilitation of capital inflow from successful crypto projects and various events to token holders.</p>
        </div>
        <div class="col-md-6 footer-box-2">
          <ul class="footer-menu onepage">
            <li><a href="" @click.prevent="$router.push('/')">Home</a></li>
            <li><a href="" @click.prevent="$router.push('/incubator')">Incubator</a></li>
            <li><a href="" @click.prevent="$router.push('/events')">Events</a></li>
            <li><a href="" @click.prevent="$router.push('/treasury')">Treasury</a></li>
            <li><a href="" @click.prevent="$router.push('/tokenomics')">Tokenomics</a></li>
          </ul>
          <div class="socials mt-4">
            <ul>
              <li><a href="https://t.me/ElrosCrypto" target="_blank"><i class="fab fa-telegram" /></a></li>
              <li><a href="https://twitter.com/ElrosCrypto" target="_blank"><i class="fa-brands fa-x-twitter" /></a></li>
              <li><a href="https://youtube.com/@elroscrypto" target="_blank"><i class="fab fa-youtube" /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="copyrights style-1">
            © Copyright ELROS Crypto 2024.<br>All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!--footer end-->
</template>

<script>
  import Vue from 'vue';
  import logo from '@/assets/png/black-gold.png';
  import { scrollToTop } from '@/utils/scroll-to-top';

  export default Vue.extend({
    name: 'el-header',
    data: () => ({
      logo,
    }),
    methods: {
      scrollToTop,
    },
  });
</script>

