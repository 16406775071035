/* eslint-disable */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { store } from '@/store';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: '404',
    component: () => import('@/views/not-found').then(
      ({ NotFound }) => NotFound,
    ),
  },

  {
    path: '/',
    name: 'Home',
    alias: ['/register'],
    component: () => import('@/views/home').then(({ Home }) => Home),
    meta: {
      title: 'ELROS Token: Resilient Crypto for Long-term Growth | Invest in Future Success',
      description: 'Discover ELROS, the cryptocurrency designed to thrive in any market. Our three pillars of Funding, Events, and Treasury ensure growth and rewards for holders. Invest today for a secure, adaptable, and lucrative crypto future.'
    },
  },

  {
    path: '/incubator',
    name: 'Incubator',
    component: () => import('@/views/incubator').then(({ Incubator }) => Incubator),
    meta: {
      title: 'ELROS Incubator Program: Building Opportunities, Maximizing Revenue',
      description: 'Explore the ELROS Incubator Program, where versatility meets revenue generation. Discover how we connect skilled service providers with clients, fueling income for the project\'s growth. Join us to access comprehensive training for future opportunities.'
    },
  },

  {
    path: '/services/:username',
    name: 'Services',
    component: () => import('@/views/services').then(({ Service }) => Service),
  },

  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/events').then(({ Events }) => Events),
    meta: {
      title: 'ELROS Events: Uniting the Community in Exciting Experiences',
      description: 'Participate in ELROS\'s engaging crypto events, including poker tournaments, web development challenges, and fitness contests. Elevate your crypto journey with fun and rewarding experiences.'
    },
  },

  {
    path: '/treasury',
    name: 'Treasury',
    component: () => import('@/views/treasury').then(({ Treasury }) => Treasury),
    meta: {
      title: 'ELROS Treasury: Your Path to Financial Freedom and Crypto Prosperity',
      description: 'Unlock the potential of the ELROS Treasury - a dynamic engine for growth. Discover how holding ELROS tokens grants you a stake in strategic crypto investments, including blue-chip assets and carefully selected DeFi projects. Participate in community-driven decisions, embrace transparency, and embark on a journey towards financial empowerment.'
    },
  },

  {
    path: '/vip-treasury',
    name: 'Vip Treasury',
    component: () => import('@/views/vip-treasury').then(({ VipTreasury }) => VipTreasury),
  },

  {
    path: '/recurring-treasury',
    name: 'Recurring Treasury',
    component: () => import('@/views/recurring-treasury').then(({ RecurringTreasury }) => RecurringTreasury),
  },

  {
    path: '/tokenomics',
    name: 'Tokenomics',
    component: () => import('@/views/tokenomics').then(({ Tokenomics }) => Tokenomics),
    meta: {
      title: 'ELROS Tokenomics: Unveiling Our Token Allocation and Design',
      description: 'Dive into the intricacies of ELROS tokenomics, a cornerstone of our crypto project\'s success. Explore our thoughtfully crafted token design, transparent allocation, and strategic distribution. Learn how ELROS aims to redefine value and sustainability in the crypto space.'
    },
  },

  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile').then(({ Profile }) => Profile),
  },

  {
    path: '/referral',
    name: 'Referral',
    component: () => import('@/views/referral').then(({ Referral }) => Referral),
  },

  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin').then(({ Admin }) => Admin),
  },

  {
    path: '/earn',
    name: 'earn',
    component: () => import('@/views/earn').then(({ Earn }) => Earn),
    meta: {
      title: 'Earn ELROS Rewards: Empowering Holders Through Collaborative Participation',
      description: 'Join the ELROS community on the Earn page and unlock the power of collaboration. Discover how as an ELROS holder, you can engage in tasks to earn rewarding benefits. Experience mutual growth and shared success as we unite to shape the future of crypto together.'
    },
  },

  {
    path: '/events/poker-battle',
    name: 'Poker Battle',
    component: () => import('@/views/poker-battle').then(({ PokerBattle }) => PokerBattle),
  },

  {
    path: '/events/poker-battle/leaderboard',
    name: 'Poker Battle Leaderboard',
    component: () => import('@/views/poker-battle-leaderboard').then(({ PokerLeaderboard }) => PokerLeaderboard),
  },

  {
    path: '/change-password',
    name: 'Change Password',
    component: () => import('@/views/change-password').then(({ ChangePassword }) => ChangePassword),
  },

  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/forgot-password').then(({ ForgotPassword }) => ForgotPassword),
  },
];

export const router = new VueRouter({
  linkActiveClass: 'is-current-route',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  mode: 'history'
});

router.beforeEach(async (to, from, next) => {
  let getToken = store.getters['session/getToken'];
  const jwtToken = localStorage.getItem('jwt') && !localStorage.getItem('jwt')!.includes('undefined');
  const loggedIn = localStorage.getItem('li');
  const { params, path, query, name } = to;
  document.title = to.meta?.title ?? 'Elros Crypto'

  if (!getToken && jwtToken && loggedIn === 'true') {
    await store.dispatch('session/fetchUser');
    getToken = store.getters['session/getToken'];
  }

  if (to?.name?.includes('Profile') && !getToken) {
    next('/');
  }

  if (to?.name?.includes('Referral') && !getToken) {
    next('/');
  }

  if (to?.name?.includes('Admin') && !getToken) {
    next('/');
  }

  if (to?.name?.includes('Vip Treasury') && !store.getters['session/isVip']) {
    next('/');
  }

  if (to?.name?.includes('Recurring Treasury') && !store.getters['session/isRecurring']) {
    next('/');
  }

  next();
});
