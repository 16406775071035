/* eslint-disable */
import Vue from 'vue';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { ModuleState, RootState } from '@/store/interfaces';
import { api } from '@/services/api';
import instance from '@/main';
import axios from 'axios';
import { i18n } from '@/i18n';
import { nanoid } from 'nanoid';
import { store } from '@/store';

const dispatchToast = (
  { title, message }: {
    title: string;
    message: string;
  },
) => {
  (instance as any).$toasts.toast({
    id: nanoid(),
    intent: 'error',
    title,
    message,
  });
};

export interface State extends ModuleState {
  isFetching: boolean;
  coins: any;
  leaderboards: any;
}

export default {
  namespaced: true,

  state: () => ({
    isFetching: false,
    coins: [],
    leaderboards: [],
  }) as State,

  actions: {
    async fetchCoinsList({ state, getters, commit, dispatch }) {
      Vue.set(state, 'isFetchingUser', true);
      let coinsFilter = '';
      getters.getAssetsCoins.forEach(({ symbol }: { symbol: string }) => {
        coinsFilter += `&filter[symbols][]=${symbol}`;
      });
      try {
        const { data: { data } } = await api.find(`v1/cryptocurrencies?page[size]=150&page[number]=1${coinsFilter}`);
        Vue.set(state, 'coins', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetchingUser', false);
      }
    },
    async fetchLeaderboards({ state, commit, dispatch }, type) {
      Vue.set(state, 'isFetching', true);
      const endpoint = type
        ? `v1/leaderboard?sort_by=${type}&sort_dir=desc`
        : 'v1/leaderboard';

      try {
        const { data: { data } }  = await api.find(endpoint);
        Vue.set(state, 'leaderboards', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async getCoinsList({ state, commit, dispatch }, token) {
      Vue.set(state, 'isFetchingUser', true);
      const url = 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?CMC_PRO_API_KEY=b76f010b-00d1-4a7b-b247-494b3dde5709&start=1&limit=10';
      try {
        const { data: { data } }  = await axios.get(`${url}`, {
          headers: {
            accept: 'application/vnd.api+json, application/json',
            'content-type': 'application/vnd.api+json',
            'Access-Control-Allow-Origin': '*',
          },
        });
        Vue.set(state, 'coins', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetchingUser', false);
      }
    },
  } as ActionTree<State, RootState>,

  getters: {
    isFetching: state => state.isFetching,
    getCoinsList: state => state.coins,
    getLeaderboards: state => state.leaderboards,
    getVipCoins: (state) => {
      return [
        { symbol: 'ETH', value: 2.245 },
        { symbol: 'BNB', value: 5.47 },
        { symbol: 'XRP', value: 800 },
        { symbol: 'ADA', value: 1000 },
        { symbol: 'MATIC', value: 390 },
        { symbol: 'SOL', value: 25 },
        { symbol: 'DOT', value: 60 },
        { symbol: 'AVAX', value: 30 },
        { symbol: 'UNI', value: 35.5 },
        { symbol: 'ATOM', value: 22.5 },
        { symbol: 'LINK', value: 17.24 },
        { symbol: 'EGLD', value: 26.6 },
        { symbol: 'GRT', value: 370 },
        { symbol: 'FIL', value: 10 },
        { symbol: 'HBAR', value: 4700 },
        { symbol: 'VET', value: 2000 },
        { symbol: 'FTM', value: 50 },
        { symbol: 'ICP', value: 1.69 },
        { symbol: 'MANA', value: 165 },
        { symbol: 'CAKE', value: 24 },
        { symbol: 'MIOTA', value: 411 },
        { symbol: 'HNT', value: 10 },
        { symbol: 'MTG', value: 36000 },
        { symbol: 'DAO', value: 415 },
        { symbol: 'SFUND', value: 45 },
        { symbol: 'BSCPAD', value: 11000 },
        { symbol: 'ETHPAD', value: 3244 },
        { symbol: 'AXS', value: 5 },
        { symbol: 'OCEAN', value: 228 },
        { symbol: 'RLC', value: 43 },
        { symbol: 'NMR', value: 5 },
        { symbol: 'AGIX', value: 1158 },
        { symbol: 'CTXC', value: 211 },
        { symbol: 'VIDT', value: 500 },
        { symbol: 'METAV', value: 2400 },
        { symbol: 'ADAPAD', value: 50000 },
        { symbol: 'LOKA', value: 40 },
        { symbol: 'POLYPAD', value: 37200 },
        { symbol: 'ILV', value: 2 },
        { symbol: 'FET', value: 337 },
        { symbol: 'RIDE', value: 800 },
        { symbol: 'QWT', value: 2500 },
        { symbol: 'GNS', value: 21 },
        { symbol: 'HYPC', value: 1000 },
        { symbol: 'OP', value: 100 },
        { symbol: 'TIA', value: 20 },
        { symbol: 'ORDI', value: 10 },
        { symbol: 'OG', value: 100 },
        { symbol: 'C98', value: 514 },
        { symbol: 'FITFI', value: 76000 },
        { symbol: 'JASMY', value: 20000 },
        { symbol: 'ACH', value: 3000 },
        { symbol: 'SOPH', value: 5538.0 },
        { symbol: 'TENET', value: 840 },
        { symbol: 'RJV', value: 4000 },
        { symbol: 'LAI', value: 7000 },
        { symbol: 'QANX', value: 12100 },
        { symbol: 'NIBI', value: 1800 },
      ];
    },
    getCoins: (state) => {
      return [
        { symbol: 'ETH', value: 1.386 },
        { symbol: 'SOL', value: 22.65 },
        { symbol: 'EGLD', value: 7 },
        { symbol: 'BNB', value: 5 },
        { symbol: 'ORDI', value: 3 },
        { symbol: 'AZR', value: 36634 },
        { symbol: 'ILV', value: 1 },
        { symbol: 'AGIX', value: 250 },
        { symbol: 'MEX', value: 5700000 },
        { symbol: 'CRO', value: 743 },
        { symbol: 'HYPC', value: 1000 },
        { symbol: 'SOPH', value: 2700 },
        { symbol: 'NIBI', value: 1800 },
      ];
    },
    getAssetsCoins: (state) => {
      return [
        { symbol: 'EGLD' },
        { symbol: 'SOL' },
        { symbol: 'ETH' },
        { symbol: 'CRO' },
        { symbol: 'MEX' },
        { symbol: 'BNB' },
        { symbol: 'BITV' },
        { symbol: 'ADA' },
        { symbol: 'ILV' },
        { symbol: 'AGIX' },
        { symbol: 'USDT' },
        { symbol: 'ADAPAD' },
        { symbol: 'SDAO' },
        { symbol: 'DAO' },
        { symbol: 'HYPC' },
        { symbol: 'XRP' },
        { symbol: 'MATIC' },
        { symbol: 'DOT' },
        { symbol: 'AVAX' },
        { symbol: 'UNI' },
        { symbol: 'ATOM' },
        { symbol: 'LINK' },
        { symbol: 'GRT' },
        { symbol: 'FIL' },
        { symbol: 'HBAR' },
        { symbol: 'VET' },
        { symbol: 'FTM' },
        { symbol: 'ICP' },
        { symbol: 'MANA' },
        { symbol: 'CAKE' },
        { symbol: 'MIOTA' },
        { symbol: 'HNT' },
        { symbol: 'MTG' },
        { symbol: 'SFUND' },
        { symbol: 'BSCPAD' },
        { symbol: 'ETHPAD' },
        { symbol: 'AXS' },
        { symbol: 'OCEAN' },
        { symbol: 'RLC' },
        { symbol: 'NMR' },
        { symbol: 'CTXC' },
        { symbol: 'VIDT' },
        { symbol: 'PHA' },
        { symbol: 'PING' },
        { symbol: 'METAV' },
        { symbol: 'LOKA' },
        { symbol: 'BONDLY' },
        { symbol: 'POLYPAD' },
        { symbol: 'FLAME' },
        { symbol: 'MNST' },
        { symbol: 'SHI' },
        { symbol: 'FET' },
        { symbol: 'RIDE' },
        { symbol: 'QWT' },
        { symbol: 'GNS' },
        { symbol: 'DUCK' },
        { symbol: 'REALM' },
        { symbol: 'USDT' },
        { symbol: 'OP' },
        { symbol: 'TIA' },
        { symbol: 'Ordi' },
        { symbol: 'OG' },
        { symbol: 'C98' },
        { symbol: 'FITFI' },
        { symbol: 'JASMY' },
        { symbol: 'ACH' },
        { symbol: 'PING' },
        { symbol: 'LOKA' },
        { symbol: 'BONDLY' },
        { symbol: 'POLYPAD' },
        { symbol: 'MNST' },
        { symbol: 'ILV' },
        { symbol: 'FET' },
        { symbol: 'RIDE' },
        { symbol: 'REALM' },
        { symbol: 'SOPH' },
        { symbol: 'TENET' },
        { symbol: 'DZOO' },
        { symbol: 'RJV' },
        { symbol: 'LAI' },
        { symbol: 'FLIP' },
        { symbol: 'VLXPAD' },
        { symbol: 'PLSPAD' },
        { symbol: 'FRM' },
        { symbol: 'POOLX' },
        { symbol: 'EJS' },
        { symbol: 'POLS' },
        { symbol: 'GAFI' },
        { symbol: 'WAG' },
        { symbol: 'OXB' },
        { symbol: 'BSCS' },
        { symbol: 'MEPAD' },
        { symbol: 'SUIP' },
        { symbol: 'AIPAD' },
        { symbol: 'ARBI' },
        { symbol: 'AZR' },
        { symbol: 'QANX' },
        { symbol: 'NIBI' },
      ];
    },
    getVipAllocation: (state) => {
      return [
        {
          wallet: '0x06d57fff68cbabd3ff57a26dd4b3190d172b7302',
          percentage: 15.38,
          initial: 3270,
        },
        {
          wallet: '0x927063bA6bC6B94D379F800a8E80825D78044A09',
          percentage: 7.57,
          initial: 1610,
        },
        {
          wallet: '0xb483D0a406aFFa253DC75D997Bb26f1e4f1A1c4C',
          percentage: 10.35,
          initial: 2200,
        },
        {
          wallet: '0x7BCdc3700E88b5E872531E41436a17eA5aF0dC13',
          percentage: 9.78,
          initial: 2080,
        },
        {
          wallet: '0x77167cC1DE22FcAa7B25cc1B3836e6a93f9A4E78',
          percentage: 20.84,
          initial: 3680,
        },
        {
          wallet: '0x129461B2544Fa462DeE9E5Bd0F26bb800a330e5d',
          percentage: 12.23,
          initial: 2600,
        },
        {
          wallet: '0x7375a1D5Bb6E5D8FCE260C8735bC80C09258B1c0',
          percentage: 4.99,
          initial: 1060,
        },
        {
          wallet: '0x3E588D8117e03fbF0279C5b2E62b1dd7649e3247',
          percentage: 9.41,
          initial: 2000,
        },
        {
          wallet: '0x8ecA4b3a42417aB891103d024882Aa4fc483B579',
          percentage: 9.45,
          initial: 2010,
        },
      ];
    },
    isMobileView: () => {
      const windowWidth = window.innerWidth;
      return windowWidth < 500;
    },
  } as GetterTree<State, RootState>,

  mutations: {
    setErrors(state: State, reason: any) {
      const { status, data: { errors } } = reason;
      const errorObject = [] as any;

      if (errors && errors.length) {
        errors.forEach((item: any) => dispatchToast({
          title: item.title || 'Error',
          message: item.detail || 'Something went wrong. Please try again',
        }));
      }
    },
  } as MutationTree<State>,
};
