/* eslint-disable */
import Vue from 'vue';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { ModuleState, RootState } from '@/store/interfaces';
import { api } from '@/services/api';
import instance from '@/main';
import { nanoid } from 'nanoid';
import { router } from '@/router';

export interface State extends ModuleState {
  isAuth: boolean;
  isFetching: boolean;
  user: any;
  users: any;
  leaderboards: any;
}

const dispatchToast = (
  { title, message }: {
    title: string;
    message: string;
  },
) => {
  (instance as any).$toasts.toast({
    id: nanoid(),
    intent: 'success',
    title,
    message,
  });
};

export default {
  namespaced: true,

  state: () => ({
    isAuth: false,
    user: {},
    users: [],
    leaderboards: [],
    isFetching: false,
  }) as State,

  actions: {
    async signUp({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } } = await api.create('signup', {
          payload: { ...credentials },
        });
        commit('setUser', data);
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your account has been successfully created. Please check your email for a confirmation message and follow the instructions to confirm your account.',
        });
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async signIn({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('login', {
          payload: { ...credentials },
        });
        commit('setUser', data);
        dispatchToast({
          title: 'Welcome back!',
          message: 'You have successfully logged in to your account.',
        });
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async forgotPassword({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('password', {
          payload: { ...credentials },
        });
        dispatchToast({
          title: 'Email sent!',
          message: 'We have sent you an email with instructions on how to reset your password. Please check your email and follow the steps to reset your password.',
        });
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async updatePassword({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.update('password', {
          payload: { ...credentials },
        });
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your password has been successfully reset. You can now log in to your account with your new password.',
        });
        router.push('/');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async tgSignIn({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('telegram_login', {
          payload: { ...credentials },
        });
        commit('setUser', data);
        dispatchToast({
          title: 'Welcome back!',
          message: 'You have successfully logged in to your account.',
        });
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async fetchUser({ state, commit, dispatch }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.find('v1/users/current');
        commit('updateUser', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
        localStorage.setItem('li', 'false');
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async fetchUsers({ state, commit, dispatch }, role) {
      Vue.set(state, 'isFetching', true);
      const endpoint = role
        ? `v1/admin/users?filter[role]=${role}`
        : 'v1/admin/users';

      try {
        const { data: { data } }  = await api.find(endpoint);
        Vue.set(state, 'users', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async fetchLeaderboards({ state, commit, dispatch }, type) {
      Vue.set(state, 'isFetching', true);
      const endpoint = type
        ? `v1/admin/users/leaderboard?sort_by=${type}&sort_dir=desc`
        : 'v1/admin/users/leaderboard';

      try {
        const { data: { data } }  = await api.find(endpoint);
        Vue.set(state, 'leaderboards', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async updateUser({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.update(`v1/users/${state.user.id}`, {
          payload: { ...credentials },
        });
        commit('updateUser', data);
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your profile was updated successfully.',
        });
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
        localStorage.setItem('li', 'false');
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async incrementScore({ state, commit, dispatch }, { user }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('v1/admin/users/increment_score', {
          payload: { ...user },
        });
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your score was updated successfully.',
        });
        dispatch('fetchUsers', 'army');
        dispatch('fetchLeaderboards', 'current_score');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async updateRole({ state, commit, dispatch }, { user }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('v1/admin/users/update_roles', {
          payload: { ...user },
        });
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your role was updated successfully.',
        });
        dispatch('fetchUsers', 'army');
        dispatch('fetchLeaderboards', 'current_score');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async confirmUser({ state, commit, dispatch }, token) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.find(`confirmation?confirmation_token=${token}`);
        commit('updateUser', data);
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    logout({ commit }) {
      commit('setUser', {});
      localStorage.setItem('li', 'false');
      router.push('/');
    },
  } as ActionTree<State, RootState>,


  getters: {
    isFetching: state => state.isFetching,
    getUser: state => state.user,
    getUsers: state => state.users,
    getLeaderboards: state => state.leaderboards,
    getToken: state => state.user && state.user?.token,
    getHoldings: state => state.user && state.user?.holdings,
    getWa: state => state.user && state.user['wallet_address'],
    isVip: (state) => {
      const walletAddress = state.user['wallet_address'] || '';
      const vipAddresses = [
        '0x06d57fff68cbabd3ff57a26dd4b3190d172b7302',
        '0x2cf7329be65c006e89e65387cc9c2fe2ebee375e',
        '0x927063ba6bc6b94d379f800a8e80825d78044a09',
        '0x7bcdc3700e88b5e872531e41436a17ea5af0dc13',
        '0x77167cc1de22fcaa7b25cc1b3836e6a93f9a4e78',
        '0x129461b2544fa462dee9e5bd0f26bb800a330e5d',
        '0x7375a1d5bb6e5d8fce260c8735bc80c09258b1c0',
        '0xe52e8df01f83f6ccedc73a11e44e775d771dc48d',
        '0xb483D0a406aFFa253DC75D997Bb26f1e4f1A1c4C',
      ].map(item => item.toLowerCase());
      return vipAddresses.includes(walletAddress.toLowerCase());
    },
  } as GetterTree<State, RootState>,

  mutations: {
    setUser(state: State, data: any) {
      const token = data?.token;
      if (data && token) {
        localStorage.setItem('jwt', `Jh${token}`);
        localStorage.setItem('li', 'true');
      }
      Vue.set(state, 'user', data);
    },
    updateUser(state: State, data: any) {
      const token = localStorage.getItem('jwt')?.slice(2);
      const newData = {
        ...data,
        token,
      };
      Vue.set(state, 'user', newData);
    },
    updateUsers(state: State, data: any) {
      Vue.set(state, 'users', data);
    },
  } as MutationTree<State>,
};
