/* eslint-disable */

/*===============================
  08. Chart function
==================================*/    

window.initTokenomics = function chart(){
  /* Layout 1 Token Chat */
  var colors = [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
    '#fbbd18',
  ];
  var labels = [
    "Development",
    "Late Investors",
    "Founders and Team",
    "Rewards",
    "Marketing",
    "Reserves",
    "LP",
    "Seed Sale",
    "Private Sale",
  ];
  var data = [5, 5, 5, 5, 5, 2.38, 25, 12.43, 35.2];
  var bgColor = colors;
  var dataChart = {
    labels: labels,
    datasets: [{
      data: data,
      backgroundColor: bgColor,
      borderWidth: 0
    }]
  };
  var config = {
    type: 'doughnut',
    data: dataChart,
    options: {
      maintainAspectRatio: false,
      cutoutPercentage: 40,
      legend: {
        display: false
      },
      legendCallback: function(chart) {
        var text = [];
        text.push('<ul class="doughnut-legend">');
        if (chart.data.datasets.length) {
          for (var i = 0; i < chart.data.datasets[0].data.length; ++i) {
            text.push('<li><span class="doughnut-legend-icon" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></span>');
            if (chart.data.labels[i]) {
              text.push('<span class="doughnut-legend-text">' + chart.data.labels[i] + '</span>');
            }
            text.push('</li>');
          }
        }
        text.push('</ul>');
        return text.join("");
      },
      tooltips: {
        yPadding: 10,
        callbacks: {
          label: function(tooltipItem, data) {
            var total = 0;
            data.datasets[tooltipItem.datasetIndex].data.forEach(function(element /*, index, array*/ ) {
              total += element;
            });
            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            var percentTxt = Math.round(value / total * 100);
            return data.labels[tooltipItem.index] + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' (' + percentTxt + '%)';
          }
        }
      }
    }
  };
  
  var ctx = document.getElementById("doughnutChart").getContext("2d");
  var doughnutChart = new Chart(ctx, config);

  var legend = doughnutChart.generateLegend();
  var legendHolder = document.getElementById("legend");
  legendHolder.innerHTML = legend;
 
   /* Layout 2 Token Chat */
  var layout2colors = ['#00876c', '#88c580', '#ffff9d', '#f7a258', '#d43d51'];
  var layout2labels = ["Seed Sale", "Private Sale - 1 year vesting", "Private Sale - 6 months vesting", "Private Sale - 3 months vesting"];
  var layout2data = [12.43, 32.64, 1.42, 1.14];
  var layout2data2 = [10, 20, 30, 30, 20];
  var bgColor = layout2colors;

  var layout2dataChart = {
    labels: layout2labels,
    datasets: [{
      data: layout2data,
      backgroundColor: bgColor,
      borderWidth: 0
    }]
  };
  var layout2dataChart2 = {
    labels: layout2labels,
    datasets: [{
      data: layout2data2,
      backgroundColor: bgColor,
      borderWidth: 0
    }]
  };
  var layout2config1 = {
    type: 'doughnut',
    data: layout2dataChart,
    options: {
      maintainAspectRatio: false,
      cutoutPercentage: 40,
      legend: {
        display: false
      },
      legendCallback: function(chart) {
        var text = [];
        text.push('<ul class="doughnut-legend">');
        if (chart.data.datasets.length) {
          for (var i = 0; i < chart.data.datasets[0].data.length; ++i) {
            text.push('<li><span class="doughnut-legend-icon" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></span>');
            if (chart.data.labels[i]) {
              text.push('<span class="doughnut-legend-text">' + chart.data.labels[i] + '</span>');
            }
            text.push('</li>');
          }
        }
        text.push('</ul>');
        return text.join("");
      },
      tooltips: {
        yPadding: 10,
        callbacks: {
          label: function(tooltipItem, data) {
            var total = 0;
            data.datasets[tooltipItem.datasetIndex].data.forEach(function(element /*, index, array*/ ) {
              total += element;
            });
            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            var percentTxt = Math.round(value / total * 100);
            return data.labels[tooltipItem.index] + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' (' + percentTxt + '%)';
          }
        }
      }
    }
  };

  var layout2config2 = {
    type: 'doughnut',
    data: layout2dataChart2,
    options: {
      maintainAspectRatio: false,
      cutoutPercentage: 40,
      legend: {
        display: false
      },
      legendCallback: function(chart) {
        var text = [];
        text.push('<ul class="doughnut-legend">');
        if (chart.data.datasets.length) {
          for (var i = 0; i < chart.data.datasets[0].data.length; ++i) {
            text.push('<li><span class="doughnut-legend-icon" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></span>');
            if (chart.data.labels[i]) {
              text.push('<span class="doughnut-legend-text">' + chart.data.labels[i] + '</span>');
            }
            text.push('</li>');
          }
        }
        text.push('</ul>');
        return text.join("");
      },
      tooltips: {
        yPadding: 10,
        callbacks: {
          label: function(tooltipItem, data) {
            var total = 0;
            data.datasets[tooltipItem.datasetIndex].data.forEach(function(element /*, index, array*/ ) {
              total += element;
            });
            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            var percentTxt = Math.round(value / total * 100);
            return data.labels[tooltipItem.index] + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' (' + percentTxt + '%)';
          }
        }
      }
    }
  };

  if($('#layout2doughnutChart1').length > 0){
    var layout2ctx1 = document.getElementById("layout2doughnutChart1").getContext("2d");
    var layout2doughnutChart1 = new Chart(layout2ctx1, layout2config1);

    var layout2legend1 = layout2doughnutChart1.generateLegend();
    var layout2legendHolder1 = document.getElementById("layout2legend1");
    layout2legendHolder1.innerHTML = layout2legend1;
  }
}

window.initTreasury = function chart(){
  var layout2colors = ['#627eea', '#3861fb', '#8a3ffc', '#23dcf5', '#cfd6e4'];
  var layout2labels = ["1,38 - ETH", "7 - EGLD", "30 - SOL", "4,97 - BNB", "Others"];
  var layout2data = [25, 21, 18, 9, 27];
  var layout2data2 = [10, 20, 30, 30, 20];
  var bgColor = layout2colors;

  var layout2dataChart = {
    labels: layout2labels,
    datasets: [{
      data: layout2data,
      backgroundColor: bgColor,
      borderWidth: 0
    }]
  };

  var layout2config1 = {
    type: 'doughnut',
    data: layout2dataChart,
    options: {
      maintainAspectRatio: false,
      cutoutPercentage: 40,
      legend: {
        display: false
      },
      legendCallback: function(chart) {
        var text = [];
        text.push('<ul class="doughnut-legend">');
        if (chart.data.datasets.length) {
          for (var i = 0; i < chart.data.datasets[0].data.length; ++i) {
            text.push('<li><span class="doughnut-legend-icon" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></span>');
            if (chart.data.labels[i]) {
              text.push('<span class="doughnut-legend-text">' + chart.data.labels[i] + '</span>');
            }
            text.push('</li>');
          }
        }
        text.push('</ul>');
        return text.join("");
      },
      tooltips: {
        yPadding: 10,
        callbacks: {
          label: function(tooltipItem, data) {
            var total = 0;
            data.datasets[tooltipItem.datasetIndex].data.forEach(function(element /*, index, array*/ ) {
              total += element;
            });
            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            var percentTxt = Math.round(value / total * 100);
            return data.labels[tooltipItem.index] + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' (' + percentTxt + '%)';
          }
        }
      }
    }
  };

  if($('#layout2doughnutChart1').length > 0){
    var layout2ctx1 = document.getElementById("layout2doughnutChart1").getContext("2d");
    var layout2doughnutChart1 = new Chart(layout2ctx1, layout2config1);

    var layout2legend1 = layout2doughnutChart1.generateLegend();
    var layout2legendHolder1 = document.getElementById("layout2legend1");
    layout2legendHolder1.innerHTML = layout2legend1;
  }
}