<template>
  <!--Header Start -->
  <header :class="getClasses">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-4 logo">
          <a href="/" title="Elros Logo">
            <img width="60" class="light" :src="logo" alt="Elros Logo">
            <img width="60" class="dark" :src="logo" alt="Elros Logo">
          </a>
        </div>
        <div class="col-sm-6 col-md-8 main-menu">
          <div class="menu-icon my-2">
            <span class="top" />
            <span class="middle" />
            <span class="bottom" />
          </div>
          <nav class="onepage">
            <ul>
              <li
                v-for="link in navLinks"
                :key="link.id"
                class="open_close"
              >
                <router-link
                  :to="link.to"
                >
                  {{ $t(link.label) }}
                </router-link>
              </li>
              <template v-if="isLoggedIn">
                <li class="open_close">
                  <router-link to="profile">Profile</router-link>
                </li>
                <li class="open_close">
                  <a href="" @click.prevent="logout">Logout</a>
                </li>
              </template>
              <li v-if="!isLoggedIn" class="nav-btn"><a href="" @click.prevent="isSignInModalOpen = true">Sign In</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <el-login-modal
      v-if="isSignInModalOpen"
      v-model="isSignInModalOpen"
    />
  </header>
  <!--Header End-->
</template>

<script>
  import Vue from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { nanoid } from 'nanoid';
  import logo from '@/assets/png/black-gold.png';
  import { NAVBAR_LINKS, SUBMENU_LINKS } from '@/constants/navbar-links';
  import { LoginModal } from '@/components/shared/login-modal';

  export default Vue.extend({
    name: 'el-header',
    components: {
      'el-login-modal': LoginModal,
    },
    data: () => ({
      logo,
      isSignInModalOpen: false,
      isLoggedIn: false,
      isFixedNavbar: false,
    }),
    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
      navLinks() {
        return NAVBAR_LINKS
          .map(item => ({ ...item, id: nanoid() }));
      },
      getUserName() {
        return this.user?.username || 'Test';
      },
      getNavbarLinks() {
        return SUBMENU_LINKS;
      },
      getClasses() {
        return {
          fixed: this.isFixedNavbar,
          'fix-it': this.isFixedNavbar,
        };
      },
    },
    watch: {
      getUser(newVal) {
        if (newVal.id) {
          this.isSignInModalOpen = false;
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      },
      $route(newVal) {
        const fixedViews = ['Tokenomics', 'Profile', 'Forgot Password', 'Change Password'];
        const referralId = this.$router.currentRoute.query?.referral_code;

        if (newVal && fixedViews.includes(newVal.name)) {
          this.isFixedNavbar = true;
        }

        if (referralId) {
          localStorage.setItem('referralId', referralId);
        }

        if (referralId && !this.getUser.id) {
          this.isSignInModalOpen = true;
        }
      },
    },
    created() {
      const referralId = this.$router.currentRoute.query?.referral_code;

      if (this.getUser.id) {
        this.isLoggedIn = true;
      }

      if (referralId) {
        localStorage.setItem('referralId', referralId);
      }
    },
    mounted() {
      window.initSmoothScroll();
    },
    methods: {
      ...mapActions({
        logout: 'session/logout',
        fetchUser: 'session/fetchUser',
      }),
    },
  });
</script>

<style>
  .navbar-container {
    background-color: transparent !important;
  }

  .navbar-container.navbar-light .navbar-nav .nav-link {
    color: #ffffff;
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .fixed .navbar-container.navbar-light .navbar-nav .nav-link {
    color: #000000;
  }

  .navbar-container.navbar-light .navbar-nav .nav-link:hover {
    color: #f6aa35 !important;
  }

  .navbar-container .dropdown-menu li {
    margin-left: 0;
  }
</style>
